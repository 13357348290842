<template>
  <div>
    <div class="row">
      <div class="col-sm-2">
        <user-social-selfies :brandId="brandId"></user-social-selfies>
      </div>
      <div class="col-sm-10">
        <pickls-insights :brandId="brandId"></pickls-insights>
      </div>
      <div class="col-sm-6">
        <store-insights :brandId="brandId"></store-insights>
      </div>
      <div class="col-sm-6">
        <state-insights :brandId="brandId"></state-insights>
      </div>
      <div class="col-sm-12" style="margin-top: 20px;">
        <IssuesInsights :brandId="brandId"></IssuesInsights>
      </div>
      <div class="col-sm-8" style="margin-top:20px;">
        <shopping-insights :brandId="brandId"></shopping-insights>
      </div>
      <div class="col-sm-4" style="margin-top:20px;">
        <product-insights :brandId="brandId"></product-insights>
      </div>
      <div class="col-sm-7" style="margin-top: 20px;">
        <survey-insights :brandId="brandId"></survey-insights>
      </div>
      <div class="col-sm-5" style="margin-top: 20px;">
        <pickl-type-insights :brandId="brandId"></pickl-type-insights>
      </div>
      <div class="col-sm-12" style="margin-top: 20px;">
        <AverageCompletionTimes :brandId="brandId"></AverageCompletionTimes>
      </div>
    </div>
  </div>
</template>

<script>
import VueApexCharts from 'vue-apexcharts'
import Card from '@/components/common/Card.vue'
import MediaPages from '@/components/Brand/Reports/MediaPages'
import ProductInsights from '@/components/Brand/Reports/ProductInsights'
import RetailerInsights from '@/components/Brand/Reports/RetailerInsights'
import ShoppingInsights from '@/components/Brand/Reports/ShoppingInsights'
import StoreInsights from '@/components/Brand/Reports/StoreInsights'
import StateInsights from '@/components/Brand/Reports/StateInsights'
import PicklsInsights from '@/components/Brand/Reports/PicklsInsights'
import SurveyInsights from '@/components/Brand/Reports/SurveyInsights.vue'
import PicklTypeInsights from '@/components/Brand/Reports/PicklTypeInsights'
import AverageCompletionTimes from '@/components/Brand/Reports/AverageCompletionTimes.vue'
import IssuesInsights from '@/components/Brand/Reports/IssuesInsights.vue'
import UserSocialSelfies from '@/components/Brand/Reports/UserSocialSelfies.vue'

export default {
  name: 'Dashboard',
  components: {
    Card,
    apexcharts: VueApexCharts,
    MediaPages,
    ProductInsights,
    RetailerInsights,
    StoreInsights,
    ShoppingInsights,
    StateInsights,
    PicklsInsights,
    SurveyInsights,
    PicklTypeInsights,
    AverageCompletionTimes,
    IssuesInsights,
    UserSocialSelfies
  },
  data: function () {
    return {
      brandId: 0,
      mixedSeries: [{
        name: 'Growth',
        type: 'column',
        data: [23, 11, 22, 27, 13, 22, 37, 21, 44, 22, 30]
      }, {
        name: 'Sales',
        type: 'area',
        data: [44, 55, 41, 67, 22, 43, 21, 41, 56, 27, 43]
      }],
      mixedchartOptions: {
        chart: {
          height: 350,
          type: 'line',
          stacked: false
        },
        stroke: {
          width: [0, 2, 5],
          curve: 'smooth'
        },
        plotOptions: {
          bar: {
            columnWidth: '50%'
          }
        },
        fill: {
          opacity: [0.85, 0.25, 1],
          gradient: {
            inverseColors: false,
            shade: 'light',
            type: 'vertical',
            opacityFrom: 0.85,
            opacityTo: 0.55,
            stops: [0, 100, 100, 100]
          }
        },
        labels: ['01/01/2003', '02/01/2003', '03/01/2003', '04/01/2003', '05/01/2003', '06/01/2003', '07/01/2003',
          '08/01/2003', '09/01/2003', '10/01/2003', '11/01/2003'
        ],
        markers: {
          size: 0
        },
        xaxis: {
          type: 'datetime'
        },
        yaxis: {
          title: {
            text: 'Tasks'
          },
          min: 0
        },
        tooltip: {
          shared: true,
          intersect: false,
          y: {
            formatter: function (y) {
              if (typeof y !== 'undefined') {
                return y.toFixed(0) + ' tasks'
              }
              return y
            }
          }
        }
      },

      pieS: [10, 90],
      swseries: [44, 55, 41, 17, 15],
      swchartOptions: {
        labels: ['California', 'Washington', 'Oregon', 'Florida', 'Texas']
      },
      PchartOptions: {
        chart: {
          width: 380,
          type: 'pie'
        },
        labels: ['Home', 'In-Store']
      },
      loadingStripeBalance: true,
      stripeBalance: 0,
      usersCountTasks: [],
      brandsCountTasks: [],
      genderDemographics: [],
      loadingPicklAverageTimes: true,
      averagePicklTimes: [],
      busy: true,
      allChartData: [],
      viewby: '1y',
      viewAverageTime: 'last-1y',
      getComparisionTime: 'last-1y',
      activeTab: 'pickl',
      chartData: [],
      ageDemographics: [],
      paymentChartData: [],
      dashboardContent: {},
      userRole: 'staff',
      userUrl: 'sadmin',
      columnD: [{
        name: 'Task Launched',
        data: [44, 55, 57, 56, 61, 58, 63, 60, 66]
      }, {
        name: 'Tasks Completed',
        data: [76, 85, 101, 98, 87, 105, 91, 114, 94]
      }, {
        name: 'Product Bought ',
        data: [5, 7, 4, 14, 3, 10, 5, 9, 6]
      }],

      columnF: [{
        name: 'Net Profit',
        data: [44, 55, 57, 56, 61, 58, 63, 60, 66]
      }, {
        name: 'Revenue',
        data: [76, 85, 101, 98, 87, 105, 91, 114, 94]
      }, {
        name: 'Free Cash Flow',
        data: [35, 41, 36, 26, 45, 48, 52, 53, 41]
      }],
      series: [{
        name: 'Insights',
        data: [250, 200, 240, 245, 200, 210, 280, 300, 150]
      }],
      chartOptions: {
        chart: {
          type: 'bar',
          height: 350
        },
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: '55%',
            endingShape: 'rounded'
          }
        },
        dataLabels: {
          enabled: false
        },
        stroke: {
          show: true,
          width: 2,
          colors: ['transparent']
        },
        xaxis: {
          categories: ['Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct']
        },
        yaxis: {
          title: {
            text: '$ (thousands)'
          }
        },
        fill: {
          opacity: 1
        }
      },
      barChartOptions: {
        chart: {
          type: 'bar',
          height: 350
        },
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: '55%',
            endingShape: 'rounded'
          }
        },
        dataLabels: {
          enabled: false
        },
        stroke: {
          show: true,
          width: 2,
          colors: ['transparent']
        },
        xaxis: {
          categories: ['Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec', 'Jan', 'Feb']
        },
        yaxis: {
          title: {
            text: 'Tasks'
          }
        },
        fill: {
          opacity: 1
        },
        tooltip: {
          y: {
            formatter: function (val) {
              return val + 'pickls'
            }
          }
        }
      }
    }
  },
  methods: {

  },
  created () {
    this.brandId = this.$store.state.userData.brandId
    this.onBoarded = this.$store.state.userData.onBoarded
  },

  mounted () {
    let userData = this.$store.state.userData
    this.userRole = userData.role
    if (this.userRole === 'super-admin') {
      this.userUrl = 'sadmin'
    } else {
      this.userUrl = 'staff'
    }
  },
  watch: {
    viewby () {
      this.getSadminReports()
    },
    viewAverageTime () {
      this.getPicklAverageTimes()
    }
  }
}
</script>

<style scoped>
.padd-graph {
  padding: 25px;
}

.tab-content {
  overflow: hidden;
}

>>> .graph-label,
>>> .apexcharts-xaxis tspan {
  color: rgba(0, 0, 0, 0.7);
  font-family: "Google Sans", "Product Sans", sans-serif !important;
}

>>> .graph-label {
  font-size: 15px;
}

>>> .apexcharts-tooltip {
  overflow: visible;
}

>>> .graph-tooltip {
  position: relative;
  background: #782b54;
  color: #fff;
  font-weight: 500;
  font-size: 14px;
  padding: 3px 10px;
  border-radius: 5px;
}

/* .graph-tooltip:after {
  top: 100%;
  left: 50%;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
  border-color: rgba(120, 43, 84, 0);
  border-top-color: #782b54;
  border-width: 5px;
  margin-left: -5px;
} */

@media only screen and (max-width: 991px) and (min-width: 576px) {
  >>> .apexcharts-xaxis-label:nth-last-child(even) {
    display: none;
  }
}

@media only screen and (max-width: 767px) and (min-width: 576px) {
  .sort-control {
    margin-top: 15px;
  }

  >>> .filter3m .apexcharts-xaxis-label,
  >>> .filter1m .apexcharts-xaxis-label {
    display: none;
  }

  >>> .filter3m .apexcharts-xaxis-label:first-child,
  >>> .filter3m .apexcharts-xaxis-label:nth-last-child(2),
  >>> .filter1m .apexcharts-xaxis-label:first-child,
  >>> .filter1m .apexcharts-xaxis-label:nth-last-child(2),
  >>> .filter3m .apexcharts-xaxis-label:nth-last-child(5),
  >>> .filter1m .apexcharts-xaxis-label:nth-last-child(4) {
    display: block;
  }
}

@media only screen and (max-width: 575px) {
  .sort-control {
    margin-top: 15px;
  }

  >>> .apexcharts-xaxis tspan {
    font-size: 12px;
  }

  .padd-graph {
    padding: 15px;
  }

  >>> .filter6m .apexcharts-xaxis-label:nth-last-child(2n) {
    display: none;
  }

  >>> .filter1y .apexcharts-xaxis-label:nth-last-child(4n-1),
  >>> .filter1y .apexcharts-xaxis-label:nth-last-child(4n),
  >>> .filter1y .apexcharts-xaxis-label:nth-last-child(4n-2) {
    display: none;
  }

  >>> .filter3m .apexcharts-xaxis-label,
  >>> .filter1m .apexcharts-xaxis-label {
    display: none;
  }

  >>> .filter3m .apexcharts-xaxis-label:first-child,
  >>> .filter3m .apexcharts-xaxis-label:nth-last-child(2),
  >>> .filter1m .apexcharts-xaxis-label:first-child,
  >>> .filter1m .apexcharts-xaxis-label:nth-last-child(2) {
    display: block;
  }
}
</style>
